/* Country search form is not linked with the dropdown menu.
 * We update the real hidden form element with clicked data.
 *
 * Somewhere in .countrySelect are .dropdown-item elements.
 * They have data-country-code as 'EE'/'ES'/'UK' etc. and
 * text as country name. Also an <img> element with a link
 * to the image svg.
 *
 * Here
 */
import Cookies from 'js-cookie';

export default function () {
  $(document).ready(function () {
    let currentCountryCode =
      Cookies.get('search_country') || window.theme.currentSearchCountryCode;
    if (currentCountryCode) {
      let $selectedCountry = $(
        'li.js-select-dropdown-item[data-country-code="' +
          currentCountryCode +
          '"]',
      );
      selectCountry(
        $selectedCountry,
        $selectedCountry.parents('.form-select-cont'),
      );
    }

    $('.js-select-dropdown-item').on('click', function () {
      let $this = $(this);
      selectCountry($this, $this.parents('.form-select-cont'));
    });
  });
}

export function selectCountry($dropdownItem, $container) {
  let $input = $container.find('.js-selector-input');
  let $name = $container.find('#country-select-dropdown-btn > .country-name');
  let $image = $container.find(
    '#country-select-dropdown-btn > .country-image > img',
  );
  let $dropdownItems = $container.find('ul.dropdowns-li-cont li.dropdown-item');

  let $currentSelection = $container.find('li.js-select-dropdown-item.current');
  let currentCountryCode = $currentSelection.data('country-code');

  let countryCode = $dropdownItem.data('country-code');
  let countryName = $dropdownItem.text().trim();
  let countryFlagUrl = $dropdownItem.find('img').attr('src');
  if (currentCountryCode === countryCode) {
    return;
  }

  $dropdownItems.removeClass('current');
  $dropdownItem.addClass('current');
  $name.text(countryName);
  $image.attr('src', countryFlagUrl);
  $input.val(countryCode).trigger('change');
}

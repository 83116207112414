export default function () {
  $(function ($) {
    $('.js-open-extra-description').click(function () {
      let $target = $($(this).data('target'));

      if (!$(this).hasClass('opened')) {
        $target.addClass('show');
        $(this).addClass('opened');
        $(this).attr('aria-expanded', 'true');
        $(this).hide();

        let randomTime = Math.floor(Math.random() * (4000 - 2000 + 1)) + 2000;

        setTimeout(function () {
          $target.find('.spinner-container').hide();
          $target.find('.js-extra-description-loading-title').hide();
          $target.find('.js-extra-description-text').show();
        }, randomTime);
      }
    });
  });
}

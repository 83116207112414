import searchForm from '../components/searchForm';
import searchFormCountrySelector from '../components/searchForm/searchFormCountrySelector';
import ninjaForms from '../components/ninjaForms';
import initSimilarCompanies from '../components/companyPage/similarCompanies.js';
import initExtraDescription from '../components/companyPage/companyExtraDescription.js';
import { initWc } from '../components/woocommerce';
import { handleFloatingButtonRedirect } from '../components/searchForm/formControl';
import {
  handleCurrencyItemClick,
  handleListItemClickZIndex,
  handleMobileNavCloseOnNavItemClick,
  handleOneTimeCollapsible,
  scrollToNextSection,
  initLogoContainer,
  showContentHideButton,
  applyScrollableIndicator,
  initCookieModal,
  initScrollAfterCloseExtraInfo,
  handleProductTagClick,
} from '../components/UITools';
import { initNotices } from '../components/noticeToast';
import { initPricingBlock } from '../components/pricingBlock/pricingBlock';
import mobileShowDocuments from '../components/companyPage/mobileShowDocuments.js';
export default {
  init() {
    // JavaScript to be fired on all pages
    initCookieModal();
    initSimilarCompanies();
    initExtraDescription();
    searchForm(); // Main search form logic, AJAX, inputs, loader
    searchFormCountrySelector();
    handleFloatingButtonRedirect();
    initLogoContainer();
    scrollToNextSection();
    ninjaForms();
    handleOneTimeCollapsible('.cl-show-more');
    handleListItemClickZIndex();
    initWc();
    initNotices();
    handleCurrencyItemClick();
    handleMobileNavCloseOnNavItemClick();
    initPricingBlock();
    showContentHideButton('.company-page-products .js-show-items');
    applyScrollableIndicator('.js-lang-switcher-scroll-indicator');
    applyScrollableIndicator('.js-currency-switcher-scroll-indicator');
    applyScrollableIndicator('.js-search-country-scroll-indicator');
    applyScrollableIndicator('.js-search-company-scroll-indicator');
    applyScrollableIndicator('.js-pricing-country-scroll-indicator');
    applyScrollableIndicator('.js-pricing-products-scroll-indicator');
    applyScrollableIndicator('.js-mini-cart-scroll-indicator');
    initScrollAfterCloseExtraInfo('#companyInfoCollapse');
    handleProductTagClick();
    mobileShowDocuments();

    $(function () {
      $('.js-langItem').on('click', function () {
        const url = $(this).data('lang-url');
        if (url) {
          window.location = url;
        }
        return false;
      });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};

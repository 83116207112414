import Cookies from 'js-cookie';
import { newNotice } from '../noticeToast';

const $countrySelectInput = $('.pricing .js-pricing-country-select-input');
const countrySelectInputNameElement = '.js-country-name';
const countrySelectInputFlagImage = '.js-country-image';
const $countrySelectDropdownMenu = $('.js-pricing .js-dropdown-menu');
const $countrySelectNonClickable = $(
  '.pricing .js-country-select-open, .pricing .js-selected-country-image, .pricing .js-pricing-input-label',
);
const $countrySelectedFlagImage = $(
  '.js-pricing .js-selected-country-image',
).find('img');
const $countrySelectDropdownMenuItem =
  $countrySelectDropdownMenu.find('.js-dropdown-item');
const $countryMenuProductLine = $('.js-pricing .js-product-line');
const $countryMapContainer = $('.js-pricing .js-map-container');
const $productsList = $('.js-pricing .js-pricing-products');
const dataTermKey = 'data-term-id';
const dataCountryCodeKey = 'data-country-code';
const $spinner = $('.js-pricing .js-pricing-products-spinner');

export function initPricingBlock() {
  initSelectedCountry();
  initHighlightedCategoriesOnMap();
  initDropdownItemClickHandler();
  initDropdownOpenIconClickHandler();
  initMapClickListener();
}

function initHighlightedCategoriesOnMap() {
  let mapData = $countryMapContainer.data();
  $.each(mapData, function (key, value) {
    if (key.startsWith('highlightCountry') && value === true) {
      let countryCode = key.replace('highlightCountry', '').toUpperCase();
      let $toBeHighlighted = $countryMapContainer.find('path.' + countryCode);

      $toBeHighlighted.addClass('highlight');
    }
  });
}

function initDropdownItemClickHandler() {
  $countrySelectDropdownMenuItem.on('click', function () {
    selectCountry($(this));
  });
}

function selectCountry($dropdownElem) {
  let selectedValue = $dropdownElem
    .find(countrySelectInputNameElement)
    .text()
    .trim();
  let selectedImgSrc = $dropdownElem
    .find(countrySelectInputFlagImage)
    .find('img')
    .attr('src');
  let termId = $dropdownElem.attr(dataTermKey);
  $countrySelectInput.val(selectedValue);
  $countrySelectInput.attr(
    dataCountryCodeKey,
    $dropdownElem.attr(dataCountryCodeKey),
  );
  $countrySelectInput.attr(dataTermKey, termId);
  $countrySelectedFlagImage.attr('src', selectedImgSrc);
  updateProductCategoryDisplay();
  $countryMenuProductLine.parent().trigger('contentUpdated');
}

function initDropdownOpenIconClickHandler() {
  $countrySelectNonClickable.on('click', function (e) {
    e.stopPropagation();
    $countrySelectInput.dropdown('toggle');
  });
}

function initSelectedCountry() {
  let currentCountryCode =
    Cookies.get('search_country') || window.theme.currentSearchCountryCode;
  if (!currentCountryCode) {
    return;
  }
  $countrySelectInput.attr(
    dataCountryCodeKey,
    currentCountryCode.toUpperCase(),
  );
  let $selectedCountry = $countrySelectDropdownMenu.find(
    '.js-dropdown-item[' +
      dataCountryCodeKey +
      '="' +
      currentCountryCode.toUpperCase() +
      '"]',
  );
  selectCountry($selectedCountry);
}

function updateProductCategoryDisplay() {
  let termIdToShow = $countrySelectInput.attr(dataTermKey);
  let countryCodeToShow = $countrySelectInput.attr(dataCountryCodeKey);
  $.ajax({
    type: 'GET',
    async: true,
    cache: true,
    url: window.theme.ajaxUrl,
    data: {
      action: 'render_pricing_products_list',
      category_id: termIdToShow,
    },
    beforeSend: function () {
      $spinner.addClass('is-loading');
      $productsList.addClass('is-loading');
    },
    success: function (response) {
      $productsList.html(response.data);
    },
    error: function () {
      newNotice('Error loading products', 'error');
    },
    complete: function () {
      $spinner.removeClass('is-loading');
      $productsList.removeClass('is-loading');
    },
  });
  updateActiveCountryOnMap(countryCodeToShow);
}

/* display selected product lines at page load */
/*function updateProductLinesVisibility(termIdToShow) {
  $countryMenuProductLine.hide();
  $('.js-product-for-cat-' + termIdToShow).show();
}*/

/* show red for the active category */
function updateActiveCountryOnMap(countryCodeToShow) {
  $countryMapContainer.find('path').removeClass('active');
  $countryMapContainer.find('path.' + countryCodeToShow).addClass('active');
}

function initMapClickListener() {
  $countryMapContainer.find('path.highlight').each(function () {
    let classes = this.className.baseVal.split(' ');

    if (classes.length) {
      classes.forEach(className => {
        // Look for a dropdown item with a matching countryCode data attribute
        let dropdownItem = $countrySelectDropdownMenu.find(
          $(`.js-dropdown-item[data-country-code='${className}']`),
        );

        // Check if the dropdown item exists
        if (dropdownItem.length) {
          // Add the click event
          $(this).on('click', function () {
            dropdownItem.trigger('click');
            $countryMenuProductLine.parent().trigger('contentUpdated');
          });
        }
      });
    }
  });
}

import {
  showMiniCartAnimation,
  updateCartHtml,
  updateMiniCartCount,
  updateMiniCartHtml,
} from './wcHelper';
import { newNotice } from '../noticeToast';

export function initProductActions() {
  let addToCart = function (productId, documentYear, documentTitle) {
    let data = {
      action: 'wc_ajax_add_to_cart',
      product_id: productId,
      document_year: documentYear,
      document_title: documentTitle,
      company_id: window.theme.companyId,
      company_hash: window.theme.companyHash,
    };

    // Trigger event.
    return $.ajax({
      type: 'post',
      url: window.theme.ajaxUrl,
      data: data,
    });
  };
  let removeFromCart = function (cartItemKey) {
    let data = {
      action: 'wc_ajax_remove_from_cart',
      cartItemKey: cartItemKey,
      isCheckoutPage: window.theme.isCheckoutPage,
    };
    return $.ajax({
      type: 'post',
      url: window.theme.ajaxUrl,
      data: data,
    });
  };

  let setDocumentState = function ($elem, isInCart) {
    if (isInCart === false) {
      $elem.removeClass('added');
    } else {
      $elem.addClass('added');
    }
  };

  let addDocumentItemToCart = function (e) {
    e.preventDefault();
    let $this = $(this);

    if ($this.data('product_id') === undefined) {
      console.log('Error adding product to cart!');
      return;
    }
    $this.addClass('added');
    $('.navbar-shop-button').addClass('is-loading');
    $this.attr('disabled', 'true');
    $this.removeClass('js-add-doc-to-cart');

    let jqxhr = addToCart(
      $this.data('product_id'),
      $this.data('document_year'),
      $this.data('document_title'),
    );
    jqxhr.done(function (response) {
      handleAjaxResponseNotices(response);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_to_cart',
        product_name: $this.data('product_sku'),
        value: $this.data('product_price'),
        currency: window.theme.currency || '',
        ecommerce: {
          value: $this.data('product_price'),
          currency: window.theme.currency || '',
          items: [
            {
              item_id: $this.data('product_sku'),
              item_name: $this.data('product_name'),
              price: $this.data('product_price'),
              item_variant: $this.data('document_year'),
              quantity: 1,
            },
          ],
        },
      });
      if (typeof response.error !== 'undefined') {
        setDocumentState($this, response.isProductInCart);
        $this.addClass('js-add-doc-to-cart');

        // ToDo Show floating error (like modal) with the contents of response.error
        return;
      } else {
        $this.data('cart_item_key', response.cartItemKey);

        $this.addClass('js-remove-doc-from-cart');
        updateMiniCartCount(response.miniCartCount);
        updateMiniCartHtml(response.miniCartHtml);
        showMiniCartAnimation();
        //$(document.body).trigger('added_to_cart', [response.miniCartHtml, response.cartHash, $this]);
      }
    });
    jqxhr.fail(function (xhr) {
      handleAjaxResponseNotices(xhr);
      $this.addClass('js-add-doc-to-cart');
      console.log('add to cart error:');
      console.log(xhr);
    });
  };

  let removeDocumentItemFromCart = function (e) {
    e.preventDefault();
    let $this = $(this);
    if (
      $this.data('cart_item_key') === undefined ||
      !$this.data('cart_item_key')
    ) {
      console.log('Error removing product from cart!');
      return;
    }
    $this.removeClass('added');
    $this.removeClass('js-remove-doc-from-cart');
    $('.navbar-shop-button').addClass('is-loading');

    let jqxhr = removeFromCart($this.data('cart_item_key'));
    jqxhr.done(function (response) {
      handleAjaxResponseNotices(response);
      if (typeof response.error !== 'undefined') {
        setDocumentState($this, response.isProductInCart);
        // Check if the error message contains the specified text
        $this.addClass('js-remove-doc-from-cart');

        // ToDo Show floating error (like modal) with the contents of response.error
        return;
      } else {
        $this.data('cart_item_key', '');

        $this.addClass('js-add-doc-to-cart');
        updateMiniCartCount(response.miniCartCount);
        updateMiniCartHtml(response.miniCartHtml);
        //$(document.body).trigger('removed_from_cart', [response.miniCartHtml, response.cartHash, $this]);
      }
    });
    jqxhr.fail(function (xhr) {
      handleAjaxResponseNotices(xhr);
      $this.addClass('js-remove-doc-from-cart');

      console.log('remove from cart error:');
      console.log(xhr);
    });
  };

  // eslint-disable-next-line no-unused-vars
  let removeCartItem = function (e) {
    e.preventDefault();
    let $this = $(this);
    if (
      $this.data('cart_item_key') === undefined ||
      !$this.data('cart_item_key')
    ) {
      console.log('Error removing product from cart!');
      return;
    }
    $('.navbar-shop-button').addClass('is-loading');
    let jqxhr = removeFromCart($this.data('cart_item_key'));
    jqxhr.done(function (response) {
      handleAjaxResponseNotices(response);
      if (typeof response.error !== 'undefined') {
        // ToDo Show floating error (like modal) with the contents of response.error
        return true;
      } else {
        findAndSetDocumentAsNotAdded($this.data('cart_item_key'));
        updateMiniCartCount(response.miniCartCount);
        updateMiniCartHtml(response.miniCartHtml);
        updateCartHtml(response.cartHtml);
        //$(document.body).trigger('removed_from_cart', [response.miniCartHtml, response.cartHash, $this]);
      }
    });
    jqxhr.fail(function (xhr) {
      handleAjaxResponseNotices(xhr);
      console.log('remove from cart error:');
      console.log(xhr);
    });
  };

  let findAndSetDocumentAsNotAdded = function (cartItemKey) {
    $('.js-remove-doc-from-cart').each(function () {
      let $this = $(this);
      if ($this.data('cart_item_key') === cartItemKey) {
        $this.data('cart_item_key', '');
        $this.removeClass('added');
        $this.removeClass('js-remove-doc-from-cart');
        $this.addClass('js-add-doc-to-cart');
      }
    });
  };

  let handleAjaxResponseNotices = function (ajaxResponse) {
    if (!ajaxResponse.notices) {
      return;
    }
    $.each(ajaxResponse.notices, function (key, notice) {
      newNotice(notice.notice, notice.type);
    });
  };

  if (typeof window.theme.miniCartCount !== 'undefined') {
    //updateMiniCartCount(window.theme.miniCartCount); // This is done in cacheUpdater already.
  }

  $(document).ready(function () {
    $(document).on('click', '.js-add-doc-to-cart', addDocumentItemToCart);

    $(document).on(
      'click',
      '.js-remove-doc-from-cart',
      removeDocumentItemFromCart,
    );

    if (!window.theme.isCheckoutPage) {
      $(document).on('click', '.js-remove-cart-item', removeCartItem);
    }
  });
}

export default function () {
  $(function ($) {
    $('.js-similar-company-link').click(function () {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'gtm_clicked_similar_company',
      });
    });
  });
}
